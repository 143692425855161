import React, { useState } from "react";
import "./admin-dashboard-sidebar.style.scss";
import { ReactComponent as Dashboard } from "../../assets/icon/dashboard.svg";
import { ReactComponent as Member } from "../../assets/icon/member.svg";
import { ReactComponent as LogOut } from "../../assets/icon/log_out.svg";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaRegQuestionCircle } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import logo from "../../assets/image/Logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";

function AdminDashboardSidebar(props) {
  const location = useLocation();
  const [, dispatch] = useStateValue();
  const navigate = useNavigate()
  const handleLogOut = () => {
    dispatch({ type: "SET_LOGIN_STATUS", status: false });
    dispatch({ type: "IS_ADMIN", status: false });
    localStorage.removeItem("token");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("isAdmin");
    navigate("/")
  };
  return (
    <section className="admin-dashboard-sidebar">
      <div className="admin-sidebar-wrapper">
        <div className="tab-container">
          <div className="tab-wrapper">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <ul className="tab">
              <li>
                <Link to="/admin/dashboard">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard" ? "active-tab" : ""
                    }`}
                  >
                    <div className="icon">
                      <Dashboard />
                    </div>
                    Dashboard
                  </div>
                </Link>
              </li>
             
              <li>
                <Link to="/admin/dashboard/members">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard/members"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <Member />
                    </div>
                    Members
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/admin/dashboard/withdraw-requests">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard/withdraw-requests"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <BiMoneyWithdraw />
                    </div>
                    Withdraw Request
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/admin/dashboard/support-tickets">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard/support-tickets"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <BiSupport />
                    </div>
                    Support Ticket
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/admin/dashboard/inquiry">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard/inquiry"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <FaRegQuestionCircle />
                    </div>
                    Inquiry 
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="log-out">
            <hr />
            <div className="logout-container " onClick={() => handleLogOut()}>
              <div className="icon">
                <LogOut />
              </div>
              Log Out
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminDashboardSidebar;
