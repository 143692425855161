import React from 'react'
import './contact-us.style.scss'

function ContactUs() {
  return (
    <div className="contact-us">
      <div className="info">
        <h2>Contact Information</h2>
        <p>
          <strong>Merchant Legal entity name:</strong> RAMILABEN DINESHBHAI CHAUDHARI
        </p>
        <p>
          <strong>Registered Address:</strong> GROUND FLOOR, TENEMENT NO-10073400/14, OLD TENEMENT NO-3400/14, SHOP NO-14, MUMBAI MARKET, HIGH WAY CHAR RASTA THARAD, AT, POSTTHARAD, TA-THARAD, Tharad, Gujarat, PIN: 385565
        </p>
        <p>
          <strong>Operational Address:</strong> GROUND FLOOR, TENEMENT NO-10073400/14, OLD TENEMENT NO-3400/14, SHOP NO-14, MUMBAI MARKET, HIGH WAY CHAR RASTA THARAD, AT, POSTTHARAD, TA-THARAD, Tharad, Gujarat, PIN: 385565
        </p>
        <p>
          <strong>Telephone No:</strong> +91 90235 66314
        </p>
        <p>
          <strong>E-Mail ID:</strong> info@aanantmarketing.com
        </p>
      </div>
    </div>
  )
}

export default ContactUs