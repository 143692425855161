// export const domainName = "http://127.0.0.1:8000"
// export const domainName = "https://coral-app-dupg7.ondigitalocean.app";
export const domainName = "https://www.api.aanantmarketing.com";
// export const domainName = "https://www.api.aanantmarketing.com";
// export const domainName = "http://195.35.6.92:5000";

export const api = {
  login: "/user/sign-in",
  adminLogin: "admin/login",
  addMember: "/user/sign-up",
  memberList: "/user/memberList",
  membersList: "/user/membersList",
  userDetail: "/user",
  forgotPassword:"/user/forgotPassword",
  changeMember:"/user/changeMember",
  resendPaymentLink:"/user/resend-payment-link",
  walletHistory:"/user/walletHistory",
  wallet:"/user/wallet",
  support:"/user/submit-support-form",
  contactUs:"/user/submit-contact-us-form",
  userDashboard:"user/dashboard",
  userMemberDetail:"/user/member-detail",
  withdraw:"/admin/withdraw",
  adminDashboard:"/admin/dashboard",
  adminMemberList:"/admin/all-member-list",
  adminUpdateMember:"/admin/update-member-status",
  supportRequest:"/admin/support-form-list",
  supportRequestStatus:"/admin/update-support-form-status",
  inquiryRequest:"/admin/inquiry-form-list",
  inquiryRequestStatus:"admin/update-inquiry-form-status",
  userDetailChange:"/admin/updateMemberDetail",
  userDetailChange:"/admin/member-detail",
  adminMemberDetail:"/admin/member-detail",

};
