import React from 'react'
import './dashboard.style.scss'
import DashboardSidebar from '../../components/dashboard-sidebar/dashboard-sidebar.component'

function Dashboard() {
    return (
       <>
       <DashboardSidebar/>
       </>
    )
}

export default Dashboard;