import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/image/Logo.png";
import bg_left from "../../assets/image/common/bg_left.png";
import "./sign-in.style.scss";
import { useForm } from "react-hook-form";
import { adminLogin, userLogin } from "../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../StateProvider";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { useState } from "react";
const SignIn = () => {
  const [, dispatch] = useStateValue();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  console.log(location.pathname.includes("admin"));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const login = async (data) => {
    dispatch({ type: "SET_LOADING_STATUS", status: true });
    let re;
    if (location.pathname.includes("admin")) {
      re = await adminLogin(data);
    } else {
      re = await userLogin(data);
    }
    if (re?.status === 200) {
      toast.success(re?.data?.message);
      localStorage.setItem("token", re?.data?.data?.token);
      localStorage.setItem("userDetail", JSON.stringify(re?.data?.data));
      dispatch({ type: "SET_LOGIN_STATUS", status: true });
      if (location.pathname.includes("admin")) {
        localStorage.setItem("isAdmin", true);
        navigate("/admin/dashboard");
        dispatch({ type: "IS_ADMIN", status: true });
      } else {
        navigate("/dashboard");
      }
    } else {
      toast.error(re?.response?.data?.message);
    }
    dispatch({ type: "SET_LOADING_STATUS", status: false });
  };

  return (
    <div className="sign-in">
      <div className="container">
        <div className="bg outer-child">
          <img src={bg_left} alt="bg_img" />
        </div>
        <div className="details outer-child">
          <img src={Logo} alt="" />
          <h1 className="login-txt">Log in</h1>
          <form onSubmit={handleSubmit((data) => login(data))}>
            <div className="input-wrapper">
              <div className="input-container">
                <label htmlFor="">
                  Email Id. <span>*</span>
                </label>
                <input
                  type="email"
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: "/^[^@ ]+@[^@ ]+.[^@ .]{2,}$/",
                      message: "Email is not valid.",
                    },
                  })}
                  placeholder="Enter Email."
                />
                {errors.email && (
                  <p className="errorMsg">{errors.email.message}</p>
                )}
              </div>
              <div className="input-container">
                <label htmlFor="">
                  Password. <span>*</span>
                </label>
                <div className="input-inner-wrapper">
                  <input
                    type={`${isPasswordVisible ? "text" : "password"}`}
                    {...register("password", {
                      required: "Password is required.",
                      minLength: {
                        value: 8,
                        message: "length should be greater then 8.",
                      },
                    })}
                    placeholder="Enter Password."
                  />
                  <div className="show-password">
                    {isPasswordVisible ? <IoEyeOff onClick={()=>setIsPasswordVisible(false)} /> : <IoEye onClick={()=>setIsPasswordVisible(true)}/>}
                  </div>
                </div>
                {errors.password && (
                  <p className="errorMsg">{errors.password.message}</p>
                )}
              </div>
            </div>
            <div className="submit-button">
              <div className="button-text">
                <button className="green-btn">login</button>
              </div>
            </div>
          </form>
          {!location.pathname.includes("admin") && (
            <div className="forgot-passwd">
              <Link className="nav-link" to="/dashboard/forgot-password">
                <span>Forgot Password?</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
