import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import SignIn from "./routes/sign-in/sign-in.component";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./routes/footer/footer.component";
import AboutUs from "./routes/about-us/about-us.componenet";
import ContactUs from "./routes/contact/contact-us.componenet";
import Packages from "./routes/packages/packages.componenet";
import WhatWeDo from "./routes/what-we-do/what-we-do.componenet";
import PrivacyPolicy from "./routes/privacy-policy/privacy-policy.component";
import TermsAndConditions from "./routes/tnc/tnc.componenet";
import ForgotPasswd from "./routes/forgot-password/forgot-password.componenet";
import ResetPasswd from "./routes/reset-password/reset-password.componenet";
import Dashboard from "./routes/dashboard/dashboard.component";
import DashboardHeader from "./components/dashboard-header/dashboard-header.component";
import DashboardHome from "./routes/dashboard/components/dashboard-home/dashboard-home.componen";
import DashboardProfile from "./routes/dashboard/components/dashboard-profile/dashboard-profile.componen";
import DashboardMembers from "./routes/dashboard/components/dashboard-members/dashboard-members.componen";
import DashboardAddMember from "./routes/dashboard/components/dashboard-add-member/dashboard-add-member.componen";
import DashboardAccount from "./routes/dashboard/components/dashboard-account/dashboard-account.componen";
import DashboardHelp from "./routes/dashboard/components/dashboard-help/dashboard-help.componen";
import RefundPolicy from "./routes/refund-policy/refund-policy.component";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./utils/Protected-routes";
import AdminDashboardHeader from "./components/admin-dashboard-header/admin-dashboard-header.component";
import AdminProtectedRoute from "./utils/Admin-Protected-routes";
import Loader from "./components/loader/loader.component";
import { useStateValue } from "./StateProvider";
import AdminDashboardMembers from "./routes/admin-dashboard/components/admin-dashboard-members/admin-dashboard-members.componen";
import AdminDashboardHome from "./routes/admin-dashboard/components/admin-dashboard-home/admin-dashboard-home.componen";
import AdminDashboardWithdraw from "./routes/admin-dashboard/components/admin-dashboard-withdraw/admin-dashboard-withdraw.componen";
import AdminDashboardSupport from "./routes/admin-dashboard/components/admin-dashboard-support/admin-dashboard-support.componen";
import AdminDashboardInquiry from "./routes/admin-dashboard/components/admin-dashboard-inquiry/admin-dashboard-inquiry.componen";

const Shop = () => {
  return <h1>I am the shop page</h1>;
};

const App = () => {
  const [{isLoading},dispatch] = useStateValue();

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navigation />
                <Footer />
              </>
            }
          >
            <Route index element={<Home />} />
            <Route path="shop" element={<Shop />} />
            {/* <Route path="login" element={<SignIn />} /> */}
            <Route path="about_us" element={<AboutUs />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="packages" element={<Packages />} />
            <Route path="what_we_do" element={<WhatWeDo />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="refund-policy" element={<RefundPolicy />} />
            <Route path="tnc" element={<TermsAndConditions />} />
          </Route>
          <Route
            path="/dashboard"
            element={
              <>
                <DashboardHeader />
              </>
            }
          >
            <Route index element={<ProtectedRoute><DashboardHome /></ProtectedRoute>} />
            <Route path="login" element={<ProtectedRoute><SignIn /></ProtectedRoute>} />
            <Route path="forgot-password" element={<ProtectedRoute><ForgotPasswd /></ProtectedRoute>} />
            <Route path="reset-password" element={<ProtectedRoute><ResetPasswd /></ProtectedRoute>} />
            <Route path="profile" element={<ProtectedRoute><DashboardProfile /></ProtectedRoute>} />
            <Route path="members" element={<ProtectedRoute><DashboardMembers /></ProtectedRoute>} />
            <Route path="add-new-member" element={<ProtectedRoute><DashboardAddMember /></ProtectedRoute>} />
            <Route path="account" element={<ProtectedRoute><DashboardAccount /></ProtectedRoute>} />
            <Route path="help" element={<ProtectedRoute><DashboardHelp /></ProtectedRoute>} />
          </Route>
          <Route
            path="/admin/dashboard"
            element={
              <>
                <AdminDashboardHeader />
              </>
            }
          >
            <Route index element={<AdminProtectedRoute><AdminDashboardHome /></AdminProtectedRoute>} />
            <Route path="login" element={<AdminProtectedRoute><SignIn /></AdminProtectedRoute>} />
            <Route path="forgot-password" element={<AdminProtectedRoute><ForgotPasswd /></AdminProtectedRoute>} />
            <Route path="reset-password" element={<AdminProtectedRoute><ResetPasswd /></AdminProtectedRoute>} />
            <Route path="members" element={<AdminProtectedRoute><AdminDashboardMembers /></AdminProtectedRoute>} />
            <Route path="withdraw-requests" element={<AdminProtectedRoute><AdminDashboardWithdraw /></AdminProtectedRoute>} />
            <Route path="support-tickets" element={<AdminProtectedRoute><AdminDashboardSupport /></AdminProtectedRoute>} />
            <Route path="inquiry" element={<AdminProtectedRoute><AdminDashboardInquiry /></AdminProtectedRoute>} />
          </Route>
        </Routes>
        {isLoading && <Loader/>}
        
      </BrowserRouter>
    </>
  );
};

export default App;
