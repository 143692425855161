import { Fragment } from "react";
import { Outlet, Link } from "react-router-dom";

import {ReactComponent  as Logo} from "../../assets/icon/Logo.svg";
import {ReactComponent  as Phone} from "../../assets/icon/phone.svg";
import "./navigation.styles.scss";
import NavLink from "../../components/nav-link/nav-link.component";


const Navigation = () => {
  return (
    <Fragment>
      <section className="header">

      <div className="container-wrapper">
        <div className="navigation ">
          <Link className="logo-container" to="/">
            {/* <img src={Logo} className="logo" alt="" /> */}
            <Logo  />
            {/* <CrwnLogo className='logo' /> */}
          </Link>
          <NavLink />
          {/* <div className="contect-number">
            <Phone />
            <span>+91 25655 15645</span>
           
          </div> */}
        </div>
      </div>
      </section>
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
