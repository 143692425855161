import "./home.style.scss";
import { useState } from "react";
import Carousel from "../../components/carousel/carousel.componenet";
import Testimonial from "../../components/testimonials/testimonial.component";
import HomeContact from "../../components/hone-contact-forn/contact-form.componenet";
import about_us from "../../assets/image/about_us.png";
import image2 from "../../assets/image/Image 138.png";
import background_img from "../../assets/image/banner.png";
import { FaLongArrowAltRight } from "react-icons/fa";
const propertyDummyData = [
  {
    // image: image1,
    price: "2,700",
    name: "Beverly Springfield",
    address:
      "2821 Lake Sevilla, Palm Harbor, TX 210 us highway, highland lake.",
    room: "4",
    bathroom: "2",
    area: "6x7.5m2",
    popular: true,
  },
  {
    image: image2,
    price: "2,700",
    name: "st. crystal",
    address: "210 us highway, highland lake, fl",
    room: "4",
    bathroom: "2",
    area: "6x7.5m2",
    popular: true,
  },
];

const images = [
  // {
  //   src: about_us,
  //   alt: "Image 1",
  //   caption: "this is text text text tesxt Text 1",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste omnis praesentium obcaecati vel distinctio doloribus ea expedita debitis ab? Dolores voluptates aut fugiat?",
  // },
  // {
  //   src: image2,
  //   alt: "Image 2",
  //   caption: "this is text text text tesxt Text 2",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste omnis praesentium obcaecati vel distinctio doloribus ea expedita debitis ab? Dolores voluptates aut fugiat?",
  // },
  {
    src: background_img,
    alt: "Image 3",
    caption: "Empower Your Future with AanatMarketing",
    description:
      "Join a community where every dream can become a business. Discover quality products, connect with like-minded entrepreneurs, and take the first step towards financial independence. AanatMarketing is your platform for growth and success. Elevate your life – Start your journey today!",
  },
];

const testimonials = [
  {
    smallImage: about_us,
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus, officia possimus molestiae eius mollitia praesentium modi minus itaque autem ratione!",
    userImage: image2,
    name: "Sample Name",
    date: "2023-01-01",
  },
  {
    smallImage: about_us,
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus, officia possimus molestiae eius mollitia praesentium modi minus itaque autem ratione!",
    userImage: image2,
    name: "Sample Name",
    date: "2023-01-01",
  },
  {
    smallImage: about_us,
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus, officia possimus molestiae eius mollitia praesentium modi minus itaque autem ratione!",
    userImage: image2,
    name: "Sample Name",
    date: "2023-01-01",
  },
  {
    smallImage: about_us,
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus, officia possimus molestiae eius mollitia praesentium modi minus itaque autem ratione!",
    userImage: image2,
    name: "Sample Name",
    date: "2023-01-01",
  },
  {
    smallImage: about_us,
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus, officia possimus molestiae eius mollitia praesentium modi minus itaque autem ratione!",
    userImage: image2,
    name: "Sample Name",
    date: "2023-01-01",
  },
  {
    smallImage: about_us,
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus, officia possimus molestiae eius mollitia praesentium modi minus itaque autem ratione!",
    userImage: image2,
    name: "Sample Name",
    date: "2023-01-01",
  },
  {
    smallImage: about_us,
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus, officia possimus molestiae eius mollitia praesentium modi minus itaque autem ratione!",
    userImage: image2,
    name: "Sample Name",
    date: "2023-01-01",
  },
  {
    smallImage: about_us,
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus, officia possimus molestiae eius mollitia praesentium modi minus itaque autem ratione!",
    userImage: image2,
    name: "Sample Name",
    date: "2023-01-01",
  },
  // Add more data objects as needed
];

const Home = () => {
  const [activeSearchOtipn, setActiveSearchOption] = useState("rent");
  return (
    <section className="home">
      <div className="banner-wrapper">
        <div className="banner-container">
          {/* <img src={background_img} alt="" /> */}
          <Carousel images={images} />

          {/* <div className="content">
            <div className="title">
              Lorem ipsum dolor sit amet consectetur. Id in commodo est id
              vitae.
            </div>
            <div className="sub-title">
              Lorem ipsum dolor sit amet consectetur. Id in commodo est id
              vitae.Lorem ipsum dolor sit amet consectetur. Id in commodo est id
              vitae.
            </div>
          </div> */}
        </div>
      </div>
      <div className="about-us" id="aboutUs">
        <div className="left">
          <div className="image-container">
            <div className="box"></div>
            <img src={about_us} alt="" />
          </div>
        </div>
        <div className="right">
          <div className="sub-title">About Us</div>
          <div className="title">Our Vision</div>
          <div className="content">
            <span>
              At AanantMarketing, we envision a world where every individual has
              the ability to shape their destiny and build a successful business
              on their terms. We aim to be a catalyst for positive change by
              providing a platform that nurtures entrepreneurship, encourages
              self-reliance, and unlocks the potential within each person.
            </span>
          </div>
          {/* <div className="title">Empowering Entrepreneurs</div>
          <div className="content">
            <span>
              AanatMarketing is built on the belief that entrepreneurship should
              be accessible to all. Whether you're a seasoned professional or a
              newcomer to the business world, we offer a platform where you can
              thrive. Our training programs, mentorship initiatives, and
              supportive community enable individuals to develop their business
              acumen and reach their full potential.
            </span>
          </div> */}
          {/* <div className="title">Join the AanatMarketing Community</div>
          <div className="content">
            <span>
              Whether you're looking for exceptional products, considering a
              business opportunity, or seeking a supportive community to grow
              with, AanatMarketing is the place for you. Join us in redefining
              the world of direct selling and discovering the endless
              possibilities that come with owning your own business. <br></br>
              AanatMarketing - Where Every Dream Becomes a Business!
            </span>
          </div> */}
          {/* <button className="primary-btn">
            View More <FaLongArrowAltRight />
          </button> */}
        </div>
      </div>
      <div className="packages-wrapper" id="packages">
        <div className="packages">
          <div className="sub-title">Packages</div>
          <div className="title">
            We Offer you Two Packages to join with us...
          </div>
          <div className="package-container">
            <div className="package-card">
              <div className="title">Essentials Pack</div>
              <div className="content">
                <div className="price">
                  Only Rs. <span>550/-</span> +18% GST <br />
                  Get the Joining Pass
                </div>
                <a href="/#contactUs">
                  <button className="primary-btn">
                    Contact us for more Details
                  </button>
                </a>
              </div>
            </div>
            <div className="package-card">
              <div className="title">Essentials Pack</div>
              <div className="content">
                <div className="price">
                  Only Rs. <span>1350/- </span> +18% GST<br />
                  Get the Joining Pass <br />
                  and Get Products
                </div>
                <a href="/#contactUs">
                  <button className="primary-btn">
                    Contact us for more Details
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Testimonial data={testimonials} /> */}
      <HomeContact />
    </section>
  );
};

export default Home;
