// import {
//   signInWithGooglePopup,
//   createUserProfileDocument,
// } from '../../utils/firebase/firebase.utils';

import { useForm } from "react-hook-form";
import Logo from "../../assets/image/common/Logo.png";
import bg_left from "../../assets/image/common/bg_left.png";
import "./reset-password.style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import { resetPassword } from "../../apiCall";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
const ResetPasswd = () => {
  const [, dispatch] = useStateValue();
  const location = useLocation();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      token: "",
      newPassword: "",
      confirmPassword: "",
    },
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
      if (key === "token") {
        setValue("token", value);
      }
    }
  }, [location.search]);
  const submitRequest = async (data) => {
    console.log(data);
    if (data.newPassword === data.confirmPassword) {
      dispatch({ type: "SET_LOADING_STATUS", status: true });
      const re = await resetPassword(data);
      if (re?.status === 200) {
        toast.success(re?.data?.message);
        navigate("/dashboard");
      } else {
        toast.error(re?.response?.data?.message);
      }
      dispatch({ type: "SET_LOADING_STATUS", status: false });
    } else {
      toast.error("The new password and confirmed password are not matching.");
    }
  };

  return (
    <div className="reset-password">
      <div className="container">
        <div className="bg outer-child">
          <img src={bg_left} alt="bg_img" />
        </div>
        <div className="details outer-child">
          <img src={Logo} alt="" />
          <h1 className="login-txt">Reset Password</h1>
          <form onSubmit={handleSubmit((data) => submitRequest(data))}>
            <div className="input-wrapper">
              <div className="input-container">
                <label htmlFor="">
                  New Password. <span>*</span>
                </label>
                <div className="input-inner-wrapper">
                  <input
                  type={`${isPasswordVisible ? "text" : "password"}`}
                    {...register("newPassword", {
                      required: "New Password is required.",
                    })}
                    placeholder="Enter New Password."
                  />
                  <div className="show-password">
                    {isPasswordVisible ? (
                      <IoEyeOff onClick={() => setIsPasswordVisible(false)} />
                    ) : (
                      <IoEye onClick={() => setIsPasswordVisible(true)} />
                    )}
                  </div>
                </div>
                {errors.newPassword && (
                  <p className="errorMsg">{errors.newPassword.message}</p>
                )}
              </div>
              <div className="input-container">
                <label htmlFor="">
                  Confirm New Password. <span>*</span>
                </label>
                <div className="input-inner-wrapper">
                  <input
                    type={`${isConfirmPasswordVisible ? "text" : "password"}`}
                    {...register("confirmPassword", {
                      required: "Confirm Password is required.",
                      minLength: {
                        value: 8,
                        message: "length should be greater then 8.",
                      },
                    })}
                    placeholder="Enter Confirm Password."
                  />
                  <div className="show-password">
                    {isConfirmPasswordVisible ? (
                      <IoEyeOff onClick={() => setIsConfirmPasswordVisible(false)} />
                    ) : (
                      <IoEye onClick={() => setIsConfirmPasswordVisible(true)} />
                    )}
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="errorMsg">{errors.confirmPassword.message}</p>
                )}
              </div>
            </div>

            <div className="submit-button">
              <div className="button-text">
                <button className="green-btn">Submit Request</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswd;
