export const initialState = {
  isLoggedIn: localStorage?.getItem("token") ? true : false,
  isAdmin: localStorage?.getItem("isAdmin") ? localStorage?.getItem("isAdmin") : false,
  isLoading:false
};

function reducer(state, action) {
  console.log(action)
  switch (action.type) {
    case "SET_LOGIN_STATUS":
      return {
        ...state,
        isLoggedIn: action.status,
      };
    case "SET_LOADING_STATUS":
      window.scrollTo(0, 0)
      return {
        ...state,
        isLoading: action.status,
      };
    case "IS_ADMIN":
      return {
        ...state,
        isAdmin: action.status,
      };

    default:
      return state;
  }
}

export default reducer;
