import * as React from "react";
import "./dashboard-account.style.scss";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { getWallet, walletHistory } from "../../../../apiCall";
import Loader from "../../../../components/loader/loader.component";
import ConformationModal from "../../../../components/modals/ConformationModal/ConformationModal";
import { MdNavigateNext } from "react-icons/md";
import WithdrawConformationModal from "../../../../components/modals/WithdrawConformationModal/WithdrawConformationModal";

function convertString(str) {
  // Split the string by underscores
  let words = str.split('_');

  // Capitalize the first letter of each word
  words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  // Join the words with spaces
  return words.join(' ');
}
const convertToDate = (str) => {
  var date = new Date(str);

  // Extract year, month, and day
  var year = date.getFullYear();
  // Note: JavaScript months are zero-based, so we add 1 to get the correct month
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Zero padding
  var day = date.getDate().toString().padStart(2, "0"); // Zero padding

  // Construct the desired date string
  var formattedDate = day + "-" + month + "-" + year;
  return formattedDate;
};

const COLUMNS = [
  {
    label: "Transaction Type",
    renderCell: (item) => convertString(item.transaction_type),
    sort: { sortKey: "TRANSACTION_TYPE" },
  },
  {
    label: "Transaction Date",
    renderCell: (item) =>
      item?.created_at ? convertToDate(item?.created_at) : "",
    sort: { sortKey: "TRANSACTION_DATE" },
  },
  {
    label: "Amount",
    renderCell: (item) => item.amount.toString(),
    sort: { sortKey: "AMOUNT" },
  },
  {
    label: "Reference ID",
    renderCell: (item) => (item?.reference_id ? item?.reference_id : "-"),
    sort: { sortKey: "REDFERENCE_ID" },
  },
  {
    label: "Status",
    renderCell: (item) => {if(item.transaction_type !== "WALLET_TRANSFER"){return item?.status ? item?.status.toLowerCase() : "pending"}else{return "-"}},
    sort: { sortKey: "STATUS" },
  },
  {
    label: "Remarks",
    renderCell: (item) => (item?.remark ? item?.remark : "-"),
    sort: { sortKey: "REMARK" },
  },
];

export default function DashboardAccount() {
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = React.useState(1);

  const onSuccess = (re) => {
    console.log("sdffffffffffffffffffffff",re)
    if (re?.status === 200) {
      // setTransectionData(re?.data?.data);
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else {
      toast.error("Something went wrongas.");
    }
  };
  const getWalletSuccess = (re) => {
    if (re?.status === 200) {
      setWallet(re?.data?.data);
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else {
      toast.error("Something went wrong.");
    }
  };
  const {
    isLoading: isTransectionListLoading,
    data: transectionListData,
    refetch,
  } = useQuery({
    queryKey: ["wallet-history",pageNumber],
    queryFn: () => walletHistory(pageNumber, 10, "ALL"),
    
    keepPreviousData: true,

    onSuccess,
  });
  const { isLoading: isWalletLoading, data: walletData,refetch:refetchWallet  } = useQuery({
    queryKey: ["wallet"],
    queryFn: () => getWallet(),
    
    onSuccess: getWalletSuccess,
    
  });
  const nextPage = () => {
    setPageNumber(prevPage => prevPage +1)
    // refetch()
  };
  const previousPage = () => {
    setPageNumber(prevPage => prevPage - 1)
    // refetch()
  };
  const [transectionData, setTransectionData] = React.useState(
    transectionListData?.data?.data ? transectionListData?.data?.data : []
  );
  const [wallet, setWallet] = React.useState(
    walletData?.data?.data ? walletData?.data?.data : []
  );
  const [show, setShow] = React.useState(false);
  console.log(wallet);

  function onSortChange(action, state) {}
 const refetchData = ()=>{
refetch()
refetchWallet()
 }
  const theme = useTheme([
    getTheme(),
    {
      HeaderRow: `
        background-color: #eaf5fd;
      `,
      Row: `
        &:nth-of-type(odd) {
          background-color: #d2e9fb;
        }

        &:nth-of-type(even) {
          background-color: #eaf5fd;
        }
      `,
    },
  ]);
  if (isTransectionListLoading) {
    return <Loader />;
  }
  if (isWalletLoading) {
    return <Loader />;
  }

  return (
    <section className="account">
      <div className="balance-container">
        <div className="available-balance-wrapper">
          <div className="title">Available Balance</div>
          <div className="amount">
            ₹ {wallet?.wallet_amount ? wallet?.wallet_amount : "0"}/-
          </div>
        </div>
        <div className="withdraw-btn">
          {" "}
          <button className="primary-btn" onClick={() => setShow(true)}>
            Withdraw
          </button>
        </div>
      </div>
      <div className="history-container">
        <div className="header-wrapper">
          <div className="title">History</div>
          {/* <div className="filter">
            <select name="" id="">
              <option value="Last 30 days">ALL</option>
              <option value="Last 30 days">Last 30 days</option>
              <option value="Last 30 days">Last 30 days</option>
            </select>
          </div> */}
        </div>
        <div className="table-wrapper">
          <CompactTable
            columns={COLUMNS}
            data={{ nodes: transectionListData?.data?.data ? transectionListData?.data?.data.list : [] }}
            theme={theme}
            // sort={sort}
          />
        </div>
      </div>
      <div className="pagination">
        <button
          type="button"
          className={`page-index ${
            pageNumber === 1 ? "" : "active"
          } previous-btn`}
          disabled={pageNumber === 1}
          onClick={() => previousPage()}
        >
          <MdNavigateNext size={30} />
        </button>
        <div className="page-count">{pageNumber}</div>
        <button
          type="button"
          className={`page-index ${
            Math.ceil(transectionListData?.data?.data?.count / 10) === pageNumber
              ? ""
              : "active"
          } next-btn`}
          disabled={Math.ceil(transectionListData?.data?.data?.count / 10) === pageNumber}
          onClick={() => nextPage()}
        >
          <MdNavigateNext size={30} />
        </button>
      </div>
      <WithdrawConformationModal
        // handfun={cancelOrder}
        refetchData={refetchData}
        wallet={wallet}
        setModalOpen={setShow}
        show={show}
        onHide={() => setShow(false)}
      />
    </section>
  );
}
