// TwoBoxes.jsx
import React from "react";
import { FaEnvelope, FaLinkedin, FaTwitter } from "react-icons/fa";
import "./home.contact.style.scss";
import { useForm } from "react-hook-form";
import { useStateValue } from "../../StateProvider";
import { useNavigate } from "react-router-dom";
import { contactUs } from "../../apiCall";
import toast from "react-hot-toast";

const HomeContact = () => {
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {},
  });
  const submitRequest = async (data) => {
    try {
      dispatch({ type: "SET_LOADING_STATUS", status: true });

      const re = await contactUs(data);
      console.log(re);
      if (re?.status === 200) {
        toast.success(re?.data?.message);
        reset();
      } else if (re?.response?.status === 401) {
        dispatch({ type: "SET_LOGIN_STATUS", status: false });
        navigate("/dashboard/login");
        localStorage.removeItem("token");
        localStorage.removeItem("userDetail");
        toast.error("Token expired");
      } else {
        toast.error(re?.response?.data?.message);
      }
      dispatch({ type: "SET_LOADING_STATUS", status: false });
    } catch {
      toast.error("Something went wrong.");
    }
  };
  return (
    <div className="contact-us-container" id="contactUs">
      <div className="box contact-info">
        <div className="sub-title">Contact Us</div>
        <h3>Find more about Aanant Marekting</h3>
        <p className="description">
          Welcome to AanantMarketing! We're delighted to connect with you.
          Whether you have a question, need assistance, or want to explore
          potential collaborations, our team is here to help. Feel free to reach
          out through the provided channels below:
        </p>
        <div className="info">
          <div className="phone">
            <p>Phone</p>
            <p>+91 90235 66314</p>
          </div>
          <div className="email">
            <p>Email</p>
            <p>info@aanantmarketing</p>
          </div>
          <div className="address">
            <p>Address</p>
            <p className="address-data">
              Ground Floor, Tenement NO-10073400/14,<br></br>
              Old Tenement NO-3400/14, Shop NO-14,<br></br>
              Mumbai Market, High Way Char Rasta,<br></br>
              Tharad, Banaskantha, Gujarat
            </p>
          </div>
          {/* <div className="followus">
            <p>Follow Us</p>
            <div className="social-icons">
              <FaEnvelope className="icon" />
              <FaLinkedin className="icon" />
              <FaTwitter className="icon" />
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="box contact-form"> */}
      <form
        className="box form-container"
        onSubmit={handleSubmit((data) => submitRequest(data))}
      >
        <div className="form-section">
          <div className="form-field">
            <div className="form-filed-inner-wrapper">
              <div>
                <span className="label">Name </span>
                <span className="required">*</span>
              </div>
              <div className="input-wrapper">
                <div className="input-field">
                  <input
                    type="text"
                    className="placeholder"
                    placeholder="Enter Your Name here..."
                    {...register("name", {
                      required: "Name is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                  />
                </div>
                {errors.name && (
                  <p className="errorMsg">{errors.name.message}</p>
                )}
              </div>
            </div>
            <div className="form-filed-inner-wrapper">
              <div>
                <span className="label">Mobile no. </span>
                <span className="required">*</span>
              </div>
              <div className="input-wrapper">
                <div className="input-field">
                  <input
                    type="number"
                    className="placeholder"
                    placeholder="Enter Your Mobile Number here..."
                    {...register("phone", {
                      required: "Mobile number is required.",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Mobile number is not valid.",
                      },
                    })}
                  />
                </div>
                {errors.phone && (
                  <p className="errorMsg">{errors.phone.message}</p>
                )}
              </div>
            </div>

            <div className="form-filed-inner-wrapper">
              <div>
                <span className="label">Email ID </span>
                <span className="required">*</span>
              </div>
              <div className="input-wrapper">
                <div className="input-field">
                  <input
                    type="email"
                    className="placeholder"
                    placeholder="Enter Your Email here..."
                    {...register("email", {
                      required: "Email is required.",
                      pattern: {
                        value: "/^[^@ ]+@[^@ ]+.[^@ .]{2,}$/",
                        message: "Email is not valid.",
                      },
                    })}
                  />
                </div>
                {errors.email && (
                  <p className="errorMsg">{errors.email.message}</p>
                )}
              </div>
            </div>

            <div className="form-filed-inner-wrapper">
              <div>
                <span className="label">Subject </span>
                <span className="required">*</span>
              </div>
              <div className="input-wrapper">
                <div className="input-field">
                  <input
                    type="text"
                    className="placeholder"
                    placeholder="Enter Your Subject here..."
                    {...register("subject", {
                      required: "Subject is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                  />
                </div>
                {errors.subject && (
                  <p className="errorMsg">{errors.subject.message}</p>
                )}
              </div>
            </div>

            <div className="form-filed-inner-wrapper">
              <div>
                <span className="label">Message </span>
                <span className="required">*</span>
              </div>
              <div className="input-wrapper">
                <div className="input-field">
                  <textarea
                    type="text"
                    className="placeholder"
                    placeholder="Enter Your Message here..."
                    rows={4}
                    {...register("message", {
                      required: "Message is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                  />
                </div>
                {errors.message && (
                  <p className="errorMsg">{errors.message.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="submit-button">
          <button className="button-text">Submit</button>
          {/* <div className="button-text">Submit</div> */}
        </div>
      </form>
    </div>
  );
};

export default HomeContact;
