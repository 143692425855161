import { useForm } from "react-hook-form";
import Logo from "../../assets/image/common/Logo.png";
import bg_left from "../../assets/image/common/bg_left.png";
import "./forgot-password.style.scss";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../StateProvider";

const ForgotPasswd = () => {
  const [,dispatch] = useStateValue();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const submitRequest = async (data) => {
    dispatch({ type: "SET_LOADING_STATUS", status: true });

    const re = await forgotPassword(data);
    if (re?.status === 200) {
      toast.success(re?.data?.message);

      // navigate("/dashboard");
    } else {
      toast.error(re?.response?.data?.message);
    }
    dispatch({ type: "SET_LOADING_STATUS", status: false });
  };
  return (
    <div className="forgot-password">
      <div className="container">
        <div className="bg outer-child">
          <img src={bg_left} alt="bg_img" />
        </div>
        <div className="details outer-child">
          <img src={Logo} alt="" />
          <h1 className="login-txt">Forget Password?</h1>
          <form onSubmit={handleSubmit((data) => submitRequest(data))}>
            <div className="input-wrapper">
              <div className="input-container">
                <label htmlFor="">
                  Email Id. <span>*</span>
                </label>
                <input
                  type="email"
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: "/^[^@ ]+@[^@ ]+.[^@ .]{2,}$/",
                      message: "Email is not valid.",
                    },
                  })}
                  placeholder="Enter Email."
                />
                {errors.email && (
                  <p className="errorMsg">{errors.email.message}</p>
                )}
              </div>
            </div>
            <div className="submit-button">
              <div className="button-text">
                <button className="green-btn">Submit Request</button>
              </div>
            </div>
          </form>

          <div className="forgot-passwd">
            <Link className="nav-link" to="/dashboard/login">
              <span>Login</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswd;
