import React, { useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import "./nav-link.styles.scss";
import { ReactComponent as Phone } from "../../assets/icon/phone.svg";

import { GiHamburgerMenu } from "react-icons/gi";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoCloseSharp } from "react-icons/io5";
import profile_pic from "../../assets/profile_pic.png";
import { ReactComponent as Dashboard } from "../../assets/icon/dashboard.svg";
import { ReactComponent as Profile } from "../../assets/icon/profile.svg";
import { ReactComponent as Member } from "../../assets/icon/member.svg";
import { ReactComponent as AddMember } from "../../assets/icon/add_member.svg";
import { ReactComponent as Account } from "../../assets/icon/account.svg";
import { ReactComponent as Help } from "../../assets/icon/help.svg";
import { ReactComponent as LogOut } from "../../assets/icon/log_out.svg";
import { MdOutlineContacts } from "react-icons/md";
import { IoHomeSharp } from "react-icons/io5";
import { SiFlatpak } from "react-icons/si";
import { FcAbout } from "react-icons/fc";
import { LuContact } from "react-icons/lu";
import log from "../../assets/image/Logo.png";
// import { ReactComponent as ProfilePic } from "../../assets/profile_pic.svg";

function NavLink(props) {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="nav-links-container">
        <Link className="nav-link" to="/">
          Home
        </Link>
        <a href="/#aboutUs" className="nav-link">
          About Us
        </a>
        {/* <Link className="nav-link" to="/what_we_do">
          Whats We Do
        </Link> */}
        <a href="/#packages" className="nav-link">
          Packages
        </a>
        <a href="/#contactUs" className="nav-link">
          Contact Us
        </a>

        <div className="contect-number nav-link">
          <Phone />
          <span>+91 90235 66314</span>
        </div>
        <div className="login-wrapper ">
        <button className="login primary-btn" onClick={()=>navigate("/dashboard/login")}>Login</button>

        </div>
        <div className="menu " onClick={handleShow}>
          <GiHamburgerMenu className="hamburger-menu-icon" size={20} />
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <IoCloseSharp onClick={handleClose} className="close-menu" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="tab-wrapper">
            <div className="logo">
              <img src={log} alt="" />
            </div>
            <ul className="tab">
              <li>
                <Link to="/" onClick={() => handleClose()}>
                  <div
                    className={`item ${
                      location.pathname === "/ds" ? "active-tab" : ""
                    }`}
                  >
                    <div className="icon">
                      <IoHomeSharp />
                    </div>
                    Home
                  </div>
                </Link>
              </li>
              <li>
                <a href="/#aboutUs" onClick={() => handleClose()}>
                  <div
                    className={`item ${
                      location.pathname === "/dashboard/profile"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      {/* <Profile /> */}
                      <FcAbout />
                    </div>
                    About Us
                  </div>
                </a>
              </li>
              <li>
                <a href="/#packages" onClick={() => handleClose()}>
                  <div
                    className={`item ${
                      location.pathname === "/dashboard/members"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <SiFlatpak />
                    </div>
                    Packages
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="#contactUs"
                  onClick={() => handleClose()}
                >
                  <div
                    className={`item ${
                      location.pathname === "/dashboard/add-new-member"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <LuContact />
                    </div>
                    contact Us
                  </div>
                </a>
              </li>
              
            </ul>
          </div>
          <div className="log-out">
            {/* <hr /> */}
            <div className="logout-container ">
              
              <button className="login primary-btn" onClick={()=>navigate("/dashboard/login")}>Login</button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default NavLink;
