// PrivacyPolicy.js
import React from "react";
import "./refund-policy.style.scss";

const RefundPolicy = () => {
  return (
    <div className="refund-policy">
      <h2>Refund Policy</h2>
      <p>
      Last updated February 01,2024
      </p>
      <h4>REFUNDS</h4>
      <p>
      All sales are final and no refund will be issued
      </p>
      <h4>QUESTIONS</h4>
      <p>
      If you have any questions concerning our return policy, please contact us at: info@aanantmarketing.com
      </p>



      

      

  

      

    

      

     </div>
  );
};

export default RefundPolicy;
