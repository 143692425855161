import React from "react";
import "./dashboard-help.style.scss";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../../StateProvider";
import { support } from "../../../../apiCall";

function DashboardHelp(props) {
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
     
    },
  });
  const submitRequest = async (data) => {
    try {
        dispatch({ type: "SET_LOADING_STATUS", status: true });

        const re = await support(data);
        if (re?.status === 200) {
          toast.success(re?.data?.message);
          reset()
        } else if (re?.response?.status === 401) {
          dispatch({ type: "SET_LOGIN_STATUS", status: false });
          navigate("/dashboard/login");
          localStorage.removeItem("token");
          localStorage.removeItem("userDetail");
          toast.error("Token expired");
        } else {
          toast.error(re?.response?.data?.message);
        }
        dispatch({ type: "SET_LOADING_STATUS", status: false });
    
    } catch {
      toast.error("Something went wrong.");
    }
    
  };
  return (
    <section className="help">
      <div className="help-wrapper">
        <div className="title">Help & Query</div>
        <form onSubmit={handleSubmit((data) => submitRequest(data))}>
          <div className="form-section inner-form">
            <div className="input-wrapper">
              <div className="input-container">
                <label htmlFor="">
                  Type of Query. <span>*</span>
                </label>
                <input
                  type="text"
                  {...register("subject", {
                    required: "Type of query is required.",
                    maxLength: {
                      value: 20,
                      message: "length should be less then 20.",
                    },
                  })}
                  placeholder="Enter type of query."
                />
                {errors.subject && (
                  <p className="errorMsg">{errors.subject.message}</p>
                )}
              </div>
              <div className="input-container">
                <label htmlFor="">
                  Transection or Reference Id No. <span>*</span>
                </label>
                <input
                  type="text"
                  {...register("referenceId", {
                    required: "Transection or reference id no is required.",
                    maxLength: {
                      value: 20,
                      message: "length should be less then 20.",
                    },
                  })}
                  placeholder="Enter No."
                />
                {errors.referenceId && (
                  <p className="errorMsg">{errors.referenceId.message}</p>
                )}
              </div>

              <div className="input-container">
                <label htmlFor="">
                  Message. <span>*</span>
                </label>
                <textarea
                  type="number"
                  {...register("message", {
                    required: "Message is required.",
                    maxLength: {
                      value: 50,
                      message: "length should be less then 50.",
                    },
                  })}
                  placeholder="Say about that..."
                />
                {errors.message && (
                  <p className="errorMsg">{errors.message.message}</p>
                )}
              </div>
            </div>
            <div className="btn-wrapper">
              <button className="green-btn submit-btn">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default DashboardHelp;
