import axios from 'axios'
import { domainName } from '../Constants'

const client = axios.create({baseURL: domainName})

export const request = ({...options}) =>{
    const token = localStorage.getItem("token")
    client.defaults.headers.common.Authorization = `${token}`

    const onSuccess = response => response
    const onError = error =>{
        return error

    }

    return client(options).then(onSuccess).catch(onError)

}