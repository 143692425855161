import React, { useState } from "react";
import chart from "../../../../assets/image/dashboard/chart.png";
import contact from "../../../../assets/image/dashboard/contact.png";
import contacts_bg from "../../../../assets/image/dashboard/contacts-bg.png";
// import arrow from "../../../../assets/image/dashboard/arrow.png";
import "./admin-dashboard-home.style.scss";
import Loader from "../../../../components/loader/loader.component";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../../StateProvider";
import { adminDashboard } from "../../../../apiCall";

function AdminDashboardHome(props) {
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const onSuccess = (re) => {
    console.log(re);
    if (re?.status === 200) {
      setDashboardData(re?.data?.data);
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else {
      toast.error("Something went wrong.");
    }
  };
  const {
    isLoading,
    data: adminDashboardData,
    refetch,
  } = useQuery({
    queryKey: ["admin dashboard data"],
    queryFn: adminDashboard,
    

    onSuccess,
  });
  const [dashboardData, setDashboardData] = useState(
    adminDashboardData?.data?.data ? adminDashboardData?.data?.data : []
  );
  if (isLoading) {
    return <Loader />;
  }
  return (
    <section className="admin-dashbord-home">
        <div className="main-container">
        <div className="container-inner">
          <div className="overview">Overview</div>
          <div className="dropdown">
            <div className="dropdown-inner">
              {/* <div className="options">Last 30 days</div> */}
              {/* dropdown options will be here */}
            </div>
          </div>
        </div>

        <div className="modules">
          <div className="modules-inner">
            <div className="card-container">
              <div className="header">
                <div className="currency">
                  <div className="currency-symbol">₹</div>
                </div>
                <div className="income">
                  <div className="total-income">
                    <div className="income-label">Total Income</div>
                    <div className="income-amount">
                      ₹{" "}
                      {dashboardData?.totalIncome
                        ? dashboardData?.totalIncome
                        : "0"}
                    </div>
                  </div>
                  {/* <div className="increase">
                    <div className="arrow">
                      <img src={arrow} alt="" />
                    </div>
                    <div className="increase-text">
                      15% increase from last month
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="card-container">
              <div className="header">
                <div className="currency">
                  <div className="currency-symbol">
                    <img src={chart} alt="" />
                  </div>
                </div>
                <div className="income">
                  <div className="total-income">
                    <div className="income-label">Total revenue</div>
                    <div className="income-amount">
                      ₹{" "}
                      {dashboardData?.totalRevenue
                        ? dashboardData?.totalRevenue
                        : "0"}
                    </div>
                  </div>
                  {/* <div className="increase">
                    <div className="arrow">
                      <img src={arrow} alt="" />
                    </div>
                    <div className="increase-text">
                      15% increase from last month
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="header">
                <div className="currency">
                  <div className="currency-symbol">
                    <img src={contacts_bg} alt="" />
                  </div>
                </div>
                <div className="income">
                  <div className="total-income">
                    <div className="income-label">Total Members</div>
                    <div className="income-amount">
                      {" "}
                      {dashboardData?.totalMember
                        ? dashboardData?.totalMember
                        : "0"}
                    </div>
                  </div>
                  {/* <div className="increase">
                    <div className="arrow">
                      <img src={arrow} alt="" />
                    </div>
                    <div className="increase-text">
                      15% increase from last month
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="header">
                <div className="currency">
                  <div className="currency-symbol">
                    <img src={contact} alt="" />
                  </div>
                </div>
                <div className="income">
                  <div className="total-income">
                    <div className="income-label">Last Week Members</div>
                    <div className="income-amount">
                      {" "}
                      {dashboardData?.lastWeekMember
                        ? dashboardData?.lastWeekMember
                        : "0"}
                    </div>
                  </div>
                  {/* <div className="increase">
                    <div className="arrow">
                      <img src={arrow} alt="" />
                    </div>
                    <div className="increase-text">
                      15% increase from last month
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="header">
                <div className="currency">
                  <div className="currency-symbol">
                    <img src={contact} alt="" />
                  </div>
                </div>
                <div className="income">
                  <div className="total-income">
                    <div className="income-label">Last Month Members</div>
                    <div className="income-amount">
                      {" "}
                      {dashboardData?.lastMonthMember
                        ? dashboardData?.lastMonthMember
                        : "0"}
                    </div>
                  </div>
                  {/* <div className="increase">
                    <div className="arrow">
                      <img src={arrow} alt="" />
                    </div>
                    <div className="increase-text">
                      15% increase from last month
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminDashboardHome;
