import React, { Fragment, useState } from "react";
import "./admin-dashboard-header.style.scss";
import default_profile_pic from "../../assets/image/default_profile_pic.png";
import { HiMenuAlt2 } from "react-icons/hi";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ReactComponent as Logo } from "../../assets/icon/Logo.svg";
import { ReactComponent as Dashboard } from "../../assets/icon/dashboard.svg";
import { ReactComponent as Profile } from "../../assets/icon/profile.svg";
import { ReactComponent as Member } from "../../assets/icon/member.svg";
import { ReactComponent as AddMember } from "../../assets/icon/add_member.svg";
import { ReactComponent as Account } from "../../assets/icon/account.svg";
import { ReactComponent as Help } from "../../assets/icon/help.svg";
import { ReactComponent as LogOut } from "../../assets/icon/log_out.svg";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaRegQuestionCircle } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import log from "../../assets/image/Logo.png";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import AdminDashboardSidebar from "../admin-dashboard-sidebar/admin-dashboard-sidebar.component";
import { useStateValue } from "../../StateProvider";

function AdminDashboardHeader(props) {
  const allowedPathname = ["/admin/dashboard/login","/admin/dashboard/forgot-password","/admin/dashboard/reset-password"];
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [, dispatch] = useStateValue();
  const navigate = useNavigate()
  const handleLogOut = () => {
    dispatch({ type: "SET_LOGIN_STATUS", status: false });
    dispatch({ type: "IS_ADMIN", status: false });
    localStorage.removeItem("token");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("isAdmin");
    navigate("/")
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Fragment>
      <section className="dashboard-header">
        <div className="menu" onClick={() => setShow(!show)}>
          {" "}
          <HiMenuAlt2 size={34} />
        </div>

        <div className="active-tab">
          {location.pathname === "/admin/dashboard"
            ? "Admin Home"
            : location.pathname
                .match(/\/dashboard\/(.+)/)[1]
                .replace(/-/g, " ")
                .replace(/\b\w/g, (match) => match.toUpperCase())}
        </div>
        <div className="profile-pic">
          <img src={default_profile_pic} alt="" />
        </div>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="tab-wrapper">
              <div className="logo">
                <img src={log} alt="" />
              </div>
              <ul className="tab">
              <li>
                <Link to="/admin/dashboard">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard" ? "active-tab" : ""
                    }`}
                    onClick={() => handleClose()}
                  >
                    <div className="icon">
                      <Dashboard />
                    </div>
                    Dashboard
                  </div>
                </Link>
              </li>
             
              <li>
                <Link to="/admin/dashboard/members">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard/members"
                        ? "active-tab"
                        : ""
                    }`}
                    onClick={() => handleClose()}

                  >
                    <div className="icon">
                      <Member />
                    </div>
                    Members
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/admin/dashboard/withdraw-requests">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard/withdraw-requests"
                        ? "active-tab"
                        : ""
                    }`}
                    onClick={() => handleClose()}

                  >
                    <div className="icon">
                      <BiMoneyWithdraw />
                    </div>
                    Withdraw Request
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/admin/dashboard/support-tickets">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard/support-tickets"
                        ? "active-tab"
                        : ""
                    }`}
                    onClick={() => handleClose()}

                  >
                    <div className="icon">
                      <BiSupport />
                    </div>
                    Support Ticket
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/admin/dashboard/inquiry">
                  <div
                    className={`item ${
                      location.pathname === "/admin/dashboard/inquiry"
                        ? "active-tab"
                        : ""
                    }`}
                    onClick={() => handleClose()}

                  >
                    <div className="icon">
                      <FaRegQuestionCircle />
                    </div>
                    Inquiry 
                  </div>
                </Link>
              </li>
            </ul>
            </div>
            <div className="log-out">
              <hr />
              <div className="logout-container " onClick={()=>handleLogOut()}>
                <div className="icon">
                  <LogOut />
                </div>
                Log Out
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </section>
      {!allowedPathname.includes(location.pathname) ? (
        <div className="dashboard-container">
          <AdminDashboardSidebar />
          <Outlet />
        </div>
      ) : (
        <div className="wrapper">
          <Outlet />
        </div>
      )}
    </Fragment>
  );
}

export default AdminDashboardHeader;
