import { useState } from "react";
import "./admin-dashboard-withdraw.style.scss";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import { allMemberList, getWithdrawAdmin } from "../../../../apiCall";
import { useQuery } from "@tanstack/react-query";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../../components/loader/loader.component";
import { RxCheckCircled } from "react-icons/rx";
import { RxCrossCircled } from "react-icons/rx";
import { MdNavigateNext } from "react-icons/md";
import { MdOutlinePending } from "react-icons/md";
import WithdrawRequestConformationModal from "../../../../components/modals/WithdrawRequestConformationModal/WithdrawRequestConformationModal";
const convertToDate = (str) => {
  var date = new Date(str);

  // Extract year, month, and day
  var year = date.getFullYear();
  // Note: JavaScript months are zero-based, so we add 1 to get the correct month
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Zero padding
  var day = date.getDate().toString().padStart(2, "0"); // Zero padding

  // Construct the desired date string
  var formattedDate = day + "-" + month + "-" + year;
  return formattedDate;
};
function AdminDashboardWithdraw(props) {
  const perPage = 10;
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [activeFilter, setActiveFilter] = useState("PENDING");
  const [transectionId, setTransectionId] = useState("");
  const [transectionStatus, setTransectionStatus] = useState("");
  const [show, setShow] = useState(false);

  const nextPage = () => {
    setPageNumber((prevPage) => prevPage + 1);
    // refetch()
  };
  const previousPage = () => {
    setPageNumber((prevPage) => prevPage - 1);
    // refetch()
  };

  const onSuccess = (re) => {
    if (re?.status === 200) {
      setWithdrawData(re?.data?.data?.list);
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else {
      toast.error("Something went wrong.");
    }
  };
  const {
    isLoading: isMemberListLoading,
    data: withdrawListData,
    refetch,
  } = useQuery({
    queryKey: ["withdraw request", pageNumber,activeFilter],
    queryFn: () => getWithdrawAdmin(pageNumber, perPage, activeFilter),

    onSuccess,
  });
  const [withdrawData, setWithdrawData] = useState(
    withdrawListData?.data?.data ? withdrawListData?.data?.data?.list : []
  );
  const handleRefetch = ()=>{
    refetch()
    
  }
  const actionChange = (event, transectionId) => {
    if (event.target.value) {
      setTransectionStatus(event.target.value);
      setTransectionId(transectionId);
      setShow(true);
    }
  };
  if (isMemberListLoading) {
    return <Loader />;
  }
  return (
    <section className="admin-withdraw">
      <div className="history-container">
        <div className="header-wrapper">
          <div className="title">Withdeaw Request</div>
          <div className="filter">
            <select name="" id="" onChange={(event)=>{setActiveFilter(event.target.value);refetch()}}>
              <option value="PENDING">Pending</option>
              <option value="FAILED">Failed</option>
              <option value="SUCCESS">Success</option>
            </select>
          </div>
        </div>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Member Id</th>
                <th>Name</th>
                <th>IFSC Code</th>
                <th>Account Number</th>
                <th>amount</th>
                <th>Transaction Type</th>
                <th>Reference Id</th>
                <th>Remark</th>
                <th>Created At</th>
                <th>status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {withdrawListData?.data?.data?.list ? (
                withdrawListData?.data?.data?.list.map((data, index) => (
                  <tr>
                    <td>{index + 1 + perPage * (pageNumber - 1)}.</td>
                    <td>{data.userDetails.member_id}</td>
                    <td>
                      {data.userDetails.account_details.account_holder_address}
                    </td>
                    <td>{data.userDetails.account_details.bank_ifsc_code}</td>
                    <td>{data.userDetails.account_details.account_number}</td>
                    <td>{data.amount}</td>
                    <td>{data.transaction_type}</td>
                    <td>{data?.reference_id?data.reference_id:"-"}</td>
                    <td>{data?.remark?data.remark:"-"}</td>
                    <td>{convertToDate(data.created_at)}</td>
                    <td>
                      {data.status === "FAILED" && (
                        <div className="fail">
                          {" "}
                          <RxCrossCircled />
                        </div>
                      )}
                      {data.status === "SUCCESS" && (
                        <div className="complated">
                          <RxCheckCircled />
                        </div>
                      )}
                      {data.status === "PENDING" && (
                        <div className="pending">
                          <MdOutlinePending /> 
                        </div>
                      )}
                    </td>

                    <td>
                      <select name="" id="" value={data.status} onChange={(event)=>actionChange(event,data._id)} disabled={data.status ==="FAILED" ||data.status ==="SUCCESS"}>
                        <option value="">Action</option>
                        <option value="FAILED">Failed</option>
                        <option value="SUCCESS">Completed</option>
                      </select>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination">
        <button
          type="button"
          className={`page-index ${
            pageNumber === 1 ? "" : "active"
          } previous-btn`}
          disabled={pageNumber === 1}
          onClick={() => previousPage()}
        >
          <MdNavigateNext size={30} />
        </button>
        <div className="page-count">{pageNumber}</div>
        <button
          type="button"
          className={`page-index ${
            Math.ceil(withdrawListData?.data?.data?.count / perPage) ===
            pageNumber
              ? ""
              : "active"
          } next-btn`}
          disabled={
            Math.ceil(withdrawListData?.data?.data?.count / perPage) ===
            pageNumber ||withdrawListData?.data?.data?.count ===0
          }
          onClick={() => nextPage()}
        >
          <MdNavigateNext size={30} />
        </button>
      </div>
      <WithdrawRequestConformationModal
        requestId={transectionId}
        status={transectionStatus}
        handleRefetch={handleRefetch}
        setModalOpen={setShow}
        show={show}
        onHide={() => setShow(false)}
      />
    </section>
  );
}

export default AdminDashboardWithdraw;
