import { useRef, useState } from "react";
import "./dashboard-members.style.scss";
import { allMemberList, userMemberDetail } from "../../../../apiCall";
import { useQuery } from "@tanstack/react-query";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../../components/loader/loader.component";
import { MdNavigateNext } from "react-icons/md";
import { RxCheckCircled } from "react-icons/rx";
import { RxCrossCircled } from "react-icons/rx";
import profile_img from "../../../../assets/image/default_profile_pic.png";
import { IoSearch } from "react-icons/io5";
import { useForm } from "react-hook-form";
const convertToDate = (str) => {
  var date = new Date(str);

  // Extract year, month, and day
  var year = date.getFullYear();
  // Note: JavaScript months are zero-based, so we add 1 to get the correct month
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Zero padding
  var day = date.getDate().toString().padStart(2, "0"); // Zero padding

  // Construct the desired date string
  var formattedDate = day + "-" + month + "-" + year;
  return formattedDate;
};

function DashboardMembers(props) {
  const perPage = 10;

  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [screenType, setScreenType] = useState("membersDetail");
  const searchInput = useRef();
  const handleSearch = () => {
    if (searchInput.current.value !== "") {
      handleViewHierarchy(searchInput.current.value.toUpperCase());
    } else {
      toast.error("Please Enter Member Id.");
    }
  };
  const onSuccess = (re) => {
    if (re?.status === 200) {
      // setMemberData(re?.data?.data);
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else {
      toast.error("Something went wrong.");
    }
  };
  const {
    isLoading: isMemberListLoading,
    data: memberListData,
    refetch,
  } = useQuery({
    queryKey: ["all-member-list", pageNumber],
    queryFn: () => allMemberList(pageNumber, perPage),

    onSuccess,
  });
  const nextPage = () => {
    setPageNumber((prevPage) => prevPage + 1);
    // refetch()
  };
  const previousPage = () => {
    setPageNumber((prevPage) => prevPage - 1);
    // refetch()
  };
  const [memberDetail, setMemberDetail] = useState([]);
  const handleViewHierarchy = async (member_id) => {
    dispatch({ type: "SET_LOADING_STATUS", status: true });
    try {
      const re = await userMemberDetail(member_id);
      console.log(re);
      if (re?.status === 200) {
        setMemberDetail(re?.data?.data);
        toast.success(re?.data?.message);
        setScreenType("hierarchyView");
      } else if (re?.response?.status === 401) {
        navigate("/dashboard/login");
        localStorage.removeItem("token");
        localStorage.removeItem("userDetail");
        toast.error("Token expired");
      } else {
        toast.error(re?.response?.data?.message);
      }
    } catch {}
    dispatch({ type: "SET_LOADING_STATUS", status: false });
  };
  const {
    register: userDetail,
    handleSubmit: handleuserDetail,
    formState: { errors: userDetailError },
    setValue,
    replace,
  } = useForm({
    defaultValues: {
      memberId: "",
      name: "",
      countryCode: "+91",
      mobileNumber: "",
      email: "",
      aadharNumber: "",
      panNumber: "",
      houseNo: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      state: "",
      accountHolderName: "",
      accountIfscCode: "",
      accountNumber: "",
      userPackageType: "",
    },
  });
  const viewMemberDetail = (memberDetail) => {
    window.scrollTo(0, 0);
    setValue("memberId", memberDetail.member_id);
    setValue("seniorMemberId", memberDetail?.senior_generated_member_id);
    setValue("name", memberDetail.user_name);
    setValue("mobileNumber", memberDetail.mobile_number);
    setValue("email", memberDetail.email);
    setValue("aadharNumber", memberDetail.aadhar_number);
    setValue("panNumber", memberDetail.pan_number);
    setValue("houseNo", memberDetail.address.house_no);
    setValue("streetAddress", memberDetail.address.street_address);
    setValue("postalCode", memberDetail.address.postal_code);
    setValue("city", memberDetail.city);
    setValue("state", memberDetail.state);
    setValue(
      "accountHolderName",
      memberDetail.account_details.account_holder_address
    );
    setValue("accountIfscCode", memberDetail.account_details.bank_ifsc_code);
    setValue("userPackageType", memberDetail.user_package_type);
    setValue("accountNumber", memberDetail.account_details.account_number);

    setScreenType("userDetail");
  };
  if (isMemberListLoading) {
    return <Loader />;
  }
  switch (screenType) {
    case "membersDetail":
      return (
        <section className="member">
          <div className="history-container">
            <div className="header-wrapper">
              <div className="title">Member List</div>
              <div className="filter">
                {/* <select name="" id="">
                  <option value="Last 30 days">Last 30 days</option>
                </select> */}
              </div>
            </div>
            <div className="table-wrapper">
              {/* <CompactTable
                columns={COLUMNS}
                data={{ nodes: memberData.length > 0 ? memberData[0].list : [] }}
                theme={theme}
                // sort={sort}
                pagination={pagination}
              /> */}
              <table>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>
                      <span
                      // onClick={() => {
                      //   setActiveSearch("memberId");
                      //   setMemberIdSearchParams("");
                      //   setEmailSearchParams("");
                      // }}
                      >
                        Member Id
                      </span>
                      <br />
                      {/* {activeSearch === "memberId" && (
                            <div className="input-wrapper">
                              <div className="input-container">
                                <input
                                  type="text"
                                  placeholder="Search by Member Id.."
                                  value={memberIdSearchParams}
                                  onChange={handleMemberIdChange}
                                />{" "}
                              </div>
                            </div>
                          )} */}
                    </th>
                    <th>Name</th>
                    <th>Mobile Number</th>

                    <th>
                      <span
                      // onClick={() => {
                      //   setActiveSearch("email");
                      //   setEmailSearchParams("");
                      //   setMemberIdSearchParams("");
                      // }}
                      >
                        Email
                      </span>
                      <br />
                      {/* {activeSearch === "email" && (
                            <div className="input-wrapper">
                              <div className="input-container">
                                <input
                                  type="text"
                                  placeholder="Search by Email.."
                                  value={emailSearchParams}
                                  onChange={handleEmailChange}
                                />
                              </div>{" "}
                            </div>
                          )} */}
                    </th>
                    <th>City</th>
                    <th>State</th>
                    <th>Package Amount</th>
                    <th>Joining Date</th>
                    <th>Is Verify</th>
                    {/* <th>Is Blocked</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {memberListData?.data?.data[0]?.list ? (
                    memberListData.data.data[0].list.map((member, index) => (
                      <tr>
                        <td>{index + 1 + perPage * (pageNumber - 1)}.</td>
                        <td>{member.member_id}</td>
                        <td>{member.user_name}</td>
                        <td>{member.mobile_number}</td>
                        <td>{member.email}</td>
                        <td>{member.city}</td>
                        <td>{member.state}</td>
                        <td>
                          {member?.user_package_type === "PRO" ? "1350" : "550"}
                        </td>
                        <td>{convertToDate(member?.joined_at)}</td>
                        <td>
                          {member.is_verify ? (
                            <div className="unblock">
                              <RxCheckCircled />
                            </div>
                          ) : (
                            <div className="block">
                              {" "}
                              <RxCrossCircled />
                            </div>
                          )}
                        </td>
                        {/* <td>
                              {!member.is_blocked ? (
                                <div className="block">
                                  {" "}
                                  <RxCrossCircled />
                                </div>
                              ) : (
                                <div className="unblock">
                                  <RxCheckCircled />
                                </div>
                              )}
                            </td> */}
                        <td>
                          <button
                            className="primary-btn"
                            onClick={() =>
                              handleViewHierarchy(member?.member_id)
                            }
                          >
                            {" "}
                            View Hierarchy
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {memberListData?.data?.data.length > 0 ? (
            <div className="pagination">
              <button
                type="button"
                className={`page-index ${
                  pageNumber === 1 ? "" : "active"
                } previous-btn`}
                disabled={pageNumber === 1}
                onClick={() => previousPage()}
              >
                <MdNavigateNext size={30} />
              </button>
              <div className="page-count">{pageNumber}</div>
              <button
                type="button"
                className={`page-index ${
                  Math.ceil(
                    memberListData?.data?.data[0].count.count / perPage
                  ) === pageNumber
                    ? ""
                    : "active"
                } next-btn`}
                disabled={
                  Math.ceil(
                    memberListData?.data?.data[0].count.count / perPage
                  ) === pageNumber ||
                  memberListData?.data?.data[0].count.count === 0
                }
                onClick={() => nextPage()}
              >
                <MdNavigateNext size={30} />
              </button>
            </div>
          ) : (
            ""
          )}
        </section>
      );
    case "hierarchyView":
      return (
        <section className="member">
          <div className="history-container">
            <div className="header-wrapper">
              <div className="title">Member Hierarchy</div>
              <div className="filter">
                {/* <select name="" id="">
                  <option value="Last 30 days">Last 30 days</option>
                </select> */}
              </div>
            </div>
            <div className="search-bar">
              <div className="input-wrapper">
                <div className="input-container">
                  <input
                    ref={searchInput}
                    type="text"
                    placeholder="Enter Member Id."
                  />
                </div>
                <div
                  className="search-icon primary-btn"
                  onClick={() => handleSearch()}
                >
                  {" "}
                  <IoSearch size={20} />
                </div>
              </div>
            </div>
            <div className="hierarchy-wrapper">
              <div className="row">
                <div
                  className={`${
                    window.innerWidth > 600
                      ? "vertical-tree"
                      : "horizontal-tree"
                  }`}
                >
                  <ul className="parent-list">
                    <li>
                      {" "}
                      <a
                        onClick={() =>
                          viewMemberDetail(memberDetail?.parent_detail)
                        }
                      >
                        <img src={profile_img} />
                        <span>{memberDetail?.parent_detail?.member_id}</span>
                      </a>
                      <ul className="child-list">
                        {memberDetail?.child_detail && (
                          <>
                            {memberDetail?.child_detail.map((member, index) => (
                              <li id={index}>
                                <a onClick={() => viewMemberDetail(member)}>
                                  <img src={profile_img} />
                                  <span>{member?.member_id}</span>
                                </a>
                              </li>
                            ))}
                          </>
                        )}
                        {/* <li>
                          <a onClick={() => viewMemberDetail()}>
                            <img src={profile_img} />
                            <span>Grand Child</span>
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a onClick={() => viewMemberDetail()}>
                            <img src={profile_img} />
                            <span>Grand Child</span>
                          </a>
                        </li>
                        <li>
                          <a onClick={() => viewMemberDetail()}>
                            <img src={profile_img} />
                            <span>Grand Child</span>
                          </a>
                        </li>
                        <li>
                          <a onClick={() => viewMemberDetail()}>
                            <img src={profile_img} />
                            <span>Grand Child</span>
                          </a>
                        </li> */}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="back-btn">
            <button
              className="primary-btn"
              onClick={() => setScreenType("membersDetail")}
            >
              Back
            </button>
          </div>
        </section>
      );

    case "userDetail":
      return (
        <section className="add-member-form">
          <form
            // onSubmit={handleuserDetail((data) =>
            //   handleChangeMemberDeatil(data)
            // )}
            disabled
          >
            <div className="top-btn-wrapper">
              <button
                className="primary-btn submit-btn"
                onClick={() => setScreenType("hierarchyView")}
              >
                Go Back
              </button>
            </div>
            <div className="form-section-1 inner-form">
              <div className="title">Personal Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">Senior Member Id.</label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("seniorMemberId", {
                      required: "seniorMemberId is required.",
                    })}
                    placeholder="Enter seniorMemberId."
                  />
                  {userDetailError.seniorMemberId && (
                    <p className="errorMsg">
                      {userDetailError.seniorMemberId.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">Member Id.</label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("memberId", {
                      required: "memberId is required.",
                    })}
                    placeholder="Enter memberId."
                  />
                  {userDetailError.memberId && (
                    <p className="errorMsg">
                      {userDetailError.memberId.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Name. <span>*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("name", {
                      required: "Name is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Name."
                  />
                  {userDetailError.name && (
                    <p className="errorMsg">{userDetailError.name.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Mobile No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    disabled
                    {...userDetail("mobileNumber", {
                      required: "Mobile number is required.",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Mobile number is not valid.",
                      },
                    })}
                    placeholder="Enter Mobile No."
                  />
                  {userDetailError.mobileNumber && (
                    <p className="errorMsg">
                      {userDetailError.mobileNumber.message}
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label htmlFor="">
                    Email. <span>*</span>
                  </label>
                  <input
                    type="email"
                    disabled
                    {...userDetail("email", {
                      required: "Email is required.",
                      pattern: {
                        value: "/^[^@ ]+@[^@ ]+.[^@ .]{2,}$/",
                        message: "Email is not valid.",
                      },
                    })}
                    placeholder="Enter Email."
                  />
                  {userDetailError.email && (
                    <p className="errorMsg">{userDetailError.email.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Aadhar No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    disabled
                    {...userDetail("aadharNumber", {
                      required: "Aadhar number is required.",
                      pattern: {
                        value: /^[0-9]{12}/,
                        message: "Aadhar number is not valid.",
                      },
                    })}
                    placeholder="Enter Aadhar No."
                  />
                  {userDetailError.aadharNumber && (
                    <p className="errorMsg">
                      {userDetailError.aadharNumber.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">PAN No.</label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("panNumber", {
                      // required: "PAN number is required.",
                      pattern: {
                        value: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                        message: "PAN number is not valid.",
                      },
                    })}
                    placeholder="Enter PAN No."
                  />
                  {userDetailError.panNumber && (
                    <p className="errorMsg">
                      {userDetailError.panNumber.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-2 inner-form">
              <div className="title">Address Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">House Name & No.</label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("houseNo", {
                      // required: "House name & number is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter House Name & No."
                  />
                  {userDetailError.houseNo && (
                    <p className="errorMsg">
                      {userDetailError.houseNo.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">Street Address.</label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("streetAddress", {
                      // required: "Street address is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Street Address."
                  />
                  {userDetailError.streetAddress && (
                    <p className="errorMsg">
                      {userDetailError.streetAddress.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    Postal Code. <span>*</span>
                  </label>
                  <input
                    type="number"
                    disabled
                    {...userDetail("postalCode", {
                      required: "Postal code is required.",
                      pattern: {
                        value: /^[0-9]{6}/,
                        message: "Postal code is not valid.",
                      },
                    })}
                    placeholder="Enter Postal Code."
                  />
                  {userDetailError.postalCode && (
                    <p className="errorMsg">
                      {userDetailError.postalCode.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    State. <span>*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("state", {
                      required: "State is required.",
                      maxLength: {
                        value: 20,
                        message: "length should be less then 20.",
                      },
                    })}
                    placeholder="Enter State."
                  />
                  {userDetailError.state && (
                    <p className="errorMsg">{userDetailError.state.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    City. <span>*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("city", {
                      required: "City is required.",
                      maxLength: {
                        value: 20,
                        message: "length should be less then 20.",
                      },
                    })}
                    placeholder="Enter City."
                  />
                  {userDetailError.city && (
                    <p className="errorMsg">{userDetailError.city.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-3 inner-form">
              <div className="title">Account Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Account Holder Name. <span>*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("accountHolderName", {
                      required: "Account holder name is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Account Holder Name."
                  />
                  {userDetailError.accountHolderName && (
                    <p className="errorMsg">
                      {userDetailError.accountHolderName.message}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="">
                    IFSC Code. <span>*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    {...userDetail("accountIfscCode", {
                      required: "IFSC code is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter IFSC Code."
                  />
                  {userDetailError.accountIfscCode && (
                    <p className="errorMsg">
                      {userDetailError.accountIfscCode.message}
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label htmlFor="">
                    Account No. <span>*</span>
                  </label>
                  <input
                    type="number"
                    disabled
                    {...userDetail("accountNumber", {
                      required: "Account number is required.",
                      maxLength: {
                        value: 50,
                        message: "length should be less then 50.",
                      },
                    })}
                    placeholder="Enter Account No."
                  />
                  {userDetailError.accountNumber && (
                    <p className="errorMsg">
                      {userDetailError.accountNumber.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-section-4 inner-form">
              <div className="title">Package Details</div>
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Package. <span>*</span>
                  </label>
                  <select
                    disabled
                    type="text"
                    {...userDetail("userPackageType", {
                      required: "Please select package",
                    })}
                    placeholder="Enter Account Holder Name."
                    defaultValue="BASIC"
                  >
                    <option value="BASIC">550/- </option>
                    <option value="PRO">1350/- </option>
                  </select>
                  {userDetailError.userPackageType && (
                    <p className="errorMsg">
                      {userDetailError.userPackageType.message}
                    </p>
                  )}
                  {/* <p className="note">
                    {" "}
                    <span>Note:-</span>During payment, 18% GST is charged. The
                    value of this page is GST excluded.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="btn-wrapper">
              <button
                className="primary-btn submit-btn"
                onClick={() => setScreenType("hierarchyView")}
              >
                Go Back
              </button>
            </div>
          </form>
        </section>
      );
  }
}

export default DashboardMembers;
