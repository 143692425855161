import React, { useState } from "react";
import "./dashboard-sidebar.style.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ReactComponent as Logo } from "../../assets/icon/Logo.svg";
import { ReactComponent as Dashboard } from "../../assets/icon/dashboard.svg";
import { ReactComponent as Profile } from "../../assets/icon/profile.svg";
import { ReactComponent as Member } from "../../assets/icon/member.svg";
import { ReactComponent as AddMember } from "../../assets/icon/add_member.svg";
import { ReactComponent as Account } from "../../assets/icon/account.svg";
import { ReactComponent as Help } from "../../assets/icon/help.svg";
import { ReactComponent as LogOut } from "../../assets/icon/log_out.svg";
import logo from "../../assets/image/Logo.png";
import { Link, useLocation } from "react-router-dom";
import { useStateValue } from "../../StateProvider";

function DashboardSidebar(props) {
  const location = useLocation();
  const [, dispatch] = useStateValue();
  const handleLogOut = () => {
    dispatch({ type: "SET_LOGIN_STATUS", status: false });
    localStorage.removeItem("token");
    localStorage.removeItem("userDetail");
  };
  return (
    <section className="dashboard-sidebar">
      <div className="sidebar-wrapper">
        <div className="tab-container">
          <div className="tab-wrapper">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <ul className="tab">
              <li>
                <Link to="/dashboard">
                  <div
                    className={`item ${
                      location.pathname === "/dashboard" ? "active-tab" : ""
                    }`}
                  >
                    <div className="icon">
                      <Dashboard />
                    </div>
                    Dashboard
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/profile">
                  <div
                    className={`item ${
                      location.pathname === "/dashboard/profile"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <Profile />
                    </div>
                    Profile
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/members">
                  <div
                    className={`item ${
                      location.pathname === "/dashboard/members"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <Member />
                    </div>
                    Members
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/add-new-member">
                  <div
                    className={`item ${
                      location.pathname === "/dashboard/add-new-member"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <AddMember />
                    </div>
                    Add New Member
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/account">
                  <div
                    className={`item ${
                      location.pathname === "/dashboard/account"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <Account />
                    </div>
                    Accounts
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/help">
                  <div
                    className={`item ${
                      location.pathname === "/dashboard/help"
                        ? "active-tab"
                        : ""
                    }`}
                  >
                    <div className="icon">
                      <Help />
                    </div>
                    Help
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="log-out">
            <hr />
            <div className="logout-container " onClick={() => handleLogOut()}>
              <div className="icon">
                <LogOut />
              </div>
              Log Out
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashboardSidebar;
