import React, { Fragment, useState } from "react";
import "./dashboard-header.style.scss";
import default_profile_pic from "../../assets/image/default_profile_pic.png";
import { HiMenuAlt2 } from "react-icons/hi";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ReactComponent as Logo } from "../../assets/icon/Logo.svg";
import { ReactComponent as Dashboard } from "../../assets/icon/dashboard.svg";
import { ReactComponent as Profile } from "../../assets/icon/profile.svg";
import { ReactComponent as Member } from "../../assets/icon/member.svg";
import { ReactComponent as AddMember } from "../../assets/icon/add_member.svg";
import { ReactComponent as Account } from "../../assets/icon/account.svg";
import { ReactComponent as Help } from "../../assets/icon/help.svg";
import { ReactComponent as LogOut } from "../../assets/icon/log_out.svg";
import log from "../../assets/image/Logo.png";
import { Outlet, Link, useLocation } from "react-router-dom";
import DashboardSidebar from "../dashboard-sidebar/dashboard-sidebar.component";
import { useStateValue } from "../../StateProvider";

function DashboardHeader(props) {
  const allowedPathname = [
    "/dashboard/login",
    "/dashboard/forgot-password",
    "/dashboard/reset-password",
  ];
  const [show, setShow] = useState(false);

  const [, dispatch] = useStateValue();

  const location = useLocation();
  const handleLogOut = () => {
    dispatch({ type: "SET_LOGIN_STATUS", status: false });
    localStorage.removeItem("token");
    localStorage.removeItem("userDetail");
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Fragment>
      <section className="dashboard-header">
        <div className="menu" onClick={() => setShow(!show)}>
          {" "}
          <HiMenuAlt2 size={34} />
        </div>

        <div className="active-tab">
          {location.pathname === "/dashboard"
            ? "Aanant Marketing"
            : location.pathname
                .match(/\/dashboard\/(.+)/)[1]
                .replace(/-/g, " ")
                .replace(/\b\w/g, (match) => match.toUpperCase())}
        </div>
        <div className="profile-pic">
          <img src={default_profile_pic} alt="" />
        </div>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="tab-wrapper">
              <div className="logo">
                <img src={log} alt="" />
              </div>
              <ul className="tab">
                <li>
                  <Link to="/dashboard" onClick={() => handleClose()}>
                    <div
                      className={`item ${
                        location.pathname === "/dashboard" ? "active-tab" : ""
                      }`}
                    >
                      <div className="icon">
                        <Dashboard />
                      </div>
                      Dashboard
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/profile" onClick={() => handleClose()}>
                    <div
                      className={`item ${
                        location.pathname === "/dashboard/profile"
                          ? "active-tab"
                          : ""
                      }`}
                    >
                      <div className="icon">
                        <Profile />
                      </div>
                      Profile
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/members" onClick={() => handleClose()}>
                    <div
                      className={`item ${
                        location.pathname === "/dashboard/members"
                          ? "active-tab"
                          : ""
                      }`}
                    >
                      <div className="icon">
                        <Member />
                      </div>
                      Members
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dashboard/add-new-member"
                    onClick={() => handleClose()}
                  >
                    <div
                      className={`item ${
                        location.pathname === "/dashboard/add-new-member"
                          ? "active-tab"
                          : ""
                      }`}
                    >
                      <div className="icon">
                        <AddMember />
                      </div>
                      Add New Member
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/account" onClick={() => handleClose()}>
                    <div
                      className={`item ${
                        location.pathname === "/dashboard/account"
                          ? "active-tab"
                          : ""
                      }`}
                    >
                      <div className="icon">
                        <Account />
                      </div>
                      Accounts
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/help" onClick={() => handleClose()}>
                    <div
                      className={`item ${
                        location.pathname === "/dashboard/help"
                          ? "active-tab"
                          : ""
                      }`}
                    >
                      <div className="icon">
                        <Help />
                      </div>
                      Help
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="log-out">
              <hr />
              <div className="logout-container " onClick={() => handleLogOut()}>
                <div className="icon">
                  <LogOut />
                </div>
                Log Out
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </section>
      {!allowedPathname.includes(location.pathname) ? (
        <div className="dashboard-container">
          <DashboardSidebar />
          <Outlet />
        </div>
      ) : (
        <div className="wrapper">
          <Outlet />
        </div>
      )}
    </Fragment>
  );
}

export default DashboardHeader;
