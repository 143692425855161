import React, { useEffect, useRef, useState } from "react";
import "./StatusConformationModal.scss";
import { Modal } from "react-bootstrap";
import {
  VerifyOTP,
  adminUpdateMember,
  inquiryRequestStatus,
  supportRequestStatus,
  withdrawRequest,
} from "../../../apiCall";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../StateProvider";
import { useForm } from "react-hook-form";

function StatusConformationModal({
  handleRefetch,
  // memberStatus,
  inquiryId,
  ticketId,
  setModalOpen,
  ...props
}) {
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {status:"COMPLETED"},
  });
  const submitRequest = async (data) => {
    try {
      dispatch({ type: "SET_LOADING_STATUS", status: true });
      let re;
      if (ticketId) {
         re = await supportRequestStatus(data);
      } else {
         re = await inquiryRequestStatus(data);
      }
      console.log(re)
      if (re?.status === 200) {
        toast.success(re?.data?.message);
        setModalOpen(false);
      } else if (re?.response?.status === 401) {
        dispatch({ type: "SET_LOGIN_STATUS", status: false });
        navigate("/dashboard/login");
        localStorage.removeItem("token");
        localStorage.removeItem("userDetail");
        toast.error("Token expired");
      } else {
        toast.error(re?.response?.data?.message);
      }
      dispatch({ type: "SET_LOADING_STATUS", status: false });
    } catch {
      toast.error("Something went wrong.");
    }
    handleRefetch();
    setModalOpen(false);
  };
  useEffect(() => {
    if (ticketId) {
      setValue("ticketId", ticketId);
    } else setValue("inquiryId", inquiryId);
  }, [inquiryId, ticketId]);
  return (
    <Modal
      {...props}
      size=""
      aria-labelledby="contained-modal-title-vcenter"
      className="modal"
      centered
    >
      <div className="conformation-modal">
        <form onSubmit={handleSubmit((data) => submitRequest(data))}>
          <div className="form-section-1 inner-form">
            <div className="title">Conform </div>
            <div className="contant">Do you want to close the request?</div>
          </div>

          <div className="btn-wrapper">
            <button className="green-btn submit-btn">Submit</button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default StatusConformationModal;
