import { useState } from "react";
import "./admin-dashboard-support.style.scss";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import {
  allMemberList,
  getWithdrawAdmin,
  supportRequest,
} from "../../../../apiCall";
import { useQuery } from "@tanstack/react-query";
import { useStateValue } from "../../../../StateProvider";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../../components/loader/loader.component";
import { RxCheckCircled } from "react-icons/rx";
import { RxCrossCircled } from "react-icons/rx";
import { MdNavigateNext } from "react-icons/md";
import { MdOutlinePending } from "react-icons/md";
import WithdrawRequestConformationModal from "../../../../components/modals/WithdrawRequestConformationModal/WithdrawRequestConformationModal";
import StatusConformationModal from "../../../../components/modals/StatusConformationModal/StatusConformationModal";
const convertToDate = (str) => {
  var date = new Date(str);

  // Extract year, month, and day
  var year = date.getFullYear();
  // Note: JavaScript months are zero-based, so we add 1 to get the correct month
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Zero padding
  var day = date.getDate().toString().padStart(2, "0"); // Zero padding

  // Construct the desired date string
  var formattedDate = day + "-" + month + "-" + year;
  return formattedDate;
};
function AdminDashboardSupport(props) {
  const perPage = 10;
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [activeFilter, setActiveFilter] = useState("ALL");
  const [ticketId, setTicketId] = useState("");
  const [show, setShow] = useState(false);

  const nextPage = () => {
    setPageNumber((prevPage) => prevPage + 1);
    // refetch()
  };
  const previousPage = () => {
    setPageNumber((prevPage) => prevPage - 1);
    // refetch()
  };

  const onSuccess = (re) => {
    if (re?.status === 200) {
    } else if (re?.response?.status === 401) {
      dispatch({ type: "SET_LOGIN_STATUS", status: false });
      navigate("/dashboard/login");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetail");
      toast.error("Token expired");
    } else {
      toast.error("Something went wrong.");
    }
  };
  const {
    isLoading,
    data: supportRequestListData,
    refetch,
  } = useQuery({
    queryKey: ["withdraw request", pageNumber, activeFilter],
    queryFn: () => supportRequest(pageNumber, perPage, activeFilter),

    onSuccess,
  });

  const handleRefetch = () => {
    refetch();
  };
  const actionChange = (event, transectionId) => {
    if (event.target.value === "COMPLETED") {
      setTicketId(transectionId);
      setShow(true);
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <section className="admin-support">
      <div className="history-container">
        <div className="header-wrapper">
          <div className="title">Support Request</div>
          <div className="filter">
            <select
              name=""
              id=""
              value={activeFilter}
              onChange={(event) => {
                setActiveFilter(event.target.value);
                refetch();
              }}
            >
              <option value="ALL">All</option>
              <option value="PENDING">Pending</option>
              <option value="COMPLETED">Completed</option>
            </select>
          </div>
        </div>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Subject</th>
                <th>Reference_id</th>
                <th>Message</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {supportRequestListData?.data?.data.length > 0 ? (
                supportRequestListData?.data?.data[0].list.map(
                  (data, index) => (
                    <tr>
                      <td>{index + 1 + perPage * (pageNumber - 1)}.</td>
                      <td>{data.subject}</td>
                      <td>{data.reference_id}</td>
                      <td>{data.message}</td>
                      <td>{convertToDate(data.created_at)}</td>

                      <td>
                        {data.status === "COMPLETED" && (
                          <div className="complated">
                            <RxCheckCircled />
                          </div>
                        )}
                        {data.status === "PENDING" && (
                          <div className="pending">
                            <MdOutlinePending />
                          </div>
                        )}
                      </td>

                      <td>
                        <select
                          name=""
                          id=""
                          value={data.status}
                          onChange={(event) => actionChange(event, data._id)}
                          disabled={data.status === "COMPLETED"}
                        >
                          <option value="PENDING">Pending</option>
                          <option value="COMPLETED">Completed</option>
                        </select>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {supportRequestListData?.data?.data.length > 0 && (
        <div className="pagination">
          <button
            type="button"
            className={`page-index ${
              pageNumber === 1 ? "" : "active"
            } previous-btn`}
            disabled={pageNumber === 1}
            onClick={() => previousPage()}
          >
            <MdNavigateNext size={30} />
          </button>
          <div className="page-count">{pageNumber}</div>
          <button
            type="button"
            className={`page-index ${
              Math.ceil(
                supportRequestListData?.data?.data[0].count.count / perPage
              ) === pageNumber
                ? ""
                : "active"
            } next-btn`}
            disabled={
              Math.ceil(
                supportRequestListData?.data?.data[0].count.count / perPage
              ) === pageNumber ||
              supportRequestListData?.data?.data[0].count.count === 0
            }
            onClick={() => nextPage()}
          >
            <MdNavigateNext size={30} />
          </button>
        </div>
      )}
      <StatusConformationModal
        ticketId={ticketId}
        handleRefetch={handleRefetch}
        setModalOpen={setShow}
        show={show}
        onHide={() => setShow(false)}
      />
    </section>
  );
}

export default AdminDashboardSupport;
