import React from "react";
import "./loader.style.scss";

function Loader(props) {
  return (
    <div className="loader-wrapper">
      <div class="spinner">
        <div class="loader l1"></div>
        <div class="loader l2"></div>
      </div>
    </div>
  );
}

export default Loader;
