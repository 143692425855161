import React from "react";
import "./footer.style.scss";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
// import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosPhonePortrait } from "react-icons/io";
import { MdMailOutline } from "react-icons/md";
import { HiMiniReceiptRefund } from "react-icons/hi2";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <div className="logo">
          Aanant Marketing
          <hr />
          <div className="social-links">
            <FaFacebook size={20} />
            <FaInstagram size={20} />
            <FaWhatsapp size={20} />
            <FaTwitter size={20} />
          </div>
          <hr />
          <div className="policy-n-tnc">
            <Link className="nav-link" to="/privacy-policy">
            <FaLock size={20} /> <span>Privacy Policy</span>
            </Link>
            <Link className="nav-link" to="/tnc">
            <FaFileAlt size={20} /> <span>Terms and Conditions</span>
            </Link>
            <Link className="nav-link" to="/refund-policy">
            <HiMiniReceiptRefund size={20} /> <span>Refund Policy</span>
            </Link>
          </div>
        </div>
        <div className="contect-detail-wrapper">
          <div className="contect-detail">
            <div className="icon">
              <IoLocationOutline  size={19} />
            </div>
            <span>
            Ground Floor, Tenement NO-10073400/14,<br></br>
              Old Tenement NO-3400/14, Shop NO-14,<br></br>
              Mumbai Market, High Way Char Rasta,<br></br>
              Tharad, Banaskantha, Gujarat
            </span>
          </div>
          <div className="contect-detail">
            <div className="icon">
              <IoIosPhonePortrait  size={19} />
            </div>
            <span>+91 942759 5378</span>
          </div>
          <div className="contect-detail">
            <div className="icon">
              <MdMailOutline  size={19}/>
            </div>
            <span>info@aanantmarketing.com</span>
          </div>
        </div>
      </section>
      <div className="rights-container">
        <div className="rights">  @aanatmarketing by spark gold.</div>
      </div>
    </>
  );
};

export default Footer;
