import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useStateValue } from "../StateProvider";

function AdminProtectedRoute({ children }) {
  const [{ isLoggedIn,isAdmin }] = useStateValue();
  // const isLoggedIn = localStorage?.getItem("token") ? true : false
  let location = useLocation();
  if(isLoggedIn && !isAdmin){
    return <Navigate to="/dashboard" state={{ from: location }} replace />;

  }
  const protectedRoutes = [
    "/admin/dashboard",
    "/dashboard/profile",
    "/dashboard/members",
    "/dashboard/add-new-member",
    "/dashboard/account",
    "/dashboard/help",
  ];

  // if (protectedRoutes.includes(location.pathname) && !isLoggedIn) {
  //   return <Navigate to="/" state={{ from: location }} replace />;
  // }
  if (
    (location.pathname === "/dashboard/reset-password" ||
    location.pathname === "/admin/dashboard/reset-password" ||
    location.pathname === "/dashboard/forgot-password" ||
    location.pathname === "/admin/dashboard/forgot-password" ||
    location.pathname === "/admin/dashboard/login" ||
    location.pathname === "/dashboard/login" )&&
    isLoggedIn && isAdmin
  ) {
    return <Navigate to="/admin/dashboard" state={{ from: location }} replace />;
  }

  return children;
}

export default AdminProtectedRoute;
