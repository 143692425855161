import React, { useEffect, useRef, useState } from "react";
import "./WithdrawRequestConformationModal.scss";
import { Modal } from "react-bootstrap";
import {
  VerifyOTP,
  updateWithdrawRequestAdmin,
  withdrawRequest,
} from "../../../apiCall";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../StateProvider";
import { useForm } from "react-hook-form";

function WithdrawRequestConformationModal({
  requestId,
  status,
  handleRefetch,
  setModalOpen,
  ...props
}) {
  console.log(requestId, status);
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      requestId: requestId,
      status: status,
      referenceId: "",
      remark: "",
    },
  });
  const submitRequest = async (data) => {
    console.log("asdasd");
    try {
      dispatch({ type: "SET_LOADING_STATUS", status: true });

      const re = await updateWithdrawRequestAdmin(data);

      if (re?.status === 200) {
        toast.success(re?.data?.message);
        reset();
        handleRefetch();
        setModalOpen(false);
      } else if (re?.response?.status === 401) {
        dispatch({ type: "SET_LOGIN_STATUS", status: false });
        navigate("/dashboard/login");
        localStorage.removeItem("token");
        localStorage.removeItem("userDetail");
        toast.error("Token expired");
      } else {
        toast.error(re?.response?.data?.message);
      }
      dispatch({ type: "SET_LOADING_STATUS", status: false });
    } catch {
      toast.error("Something went wrong.");
    }
  };
  useEffect(() => {
    setValue("requestId", requestId);
    setValue("status", status);
  }, [requestId, status]);
  return (
    <Modal
      {...props}
      size=""
      aria-labelledby="contained-modal-title-vcenter"
      className="modal"
      centered
    >
      <div className="withdraw-request-conformation-modal">
        <form onSubmit={handleSubmit((data) => submitRequest(data))}>
          <div className="form-section-1 inner-form">
            <div className="title">Withdraw Action</div>
            {/* <div className="amount-wrapper">
              <div className="title">Available Balance</div>
              <div className="amount">₹ {wallet?.wallet_amount}/-</div>
            </div> */}
            {status === "SUCCESS" && (
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Reference Id. <span>*</span>
                  </label>
                  <input
                    type="text"
                    {...register("referenceId", {
                      required: {
                        value: status === "SUCCESS",
                        message: "Reference Id is required.",
                      },
                    })}
                    placeholder="Enter Reference Id"
                  />
                  {errors.referenceId && (
                    <p className="errorMsg">{errors.referenceId.message}</p>
                  )}
                </div>
              </div>
            )}
            {status === "FAILED" && (
              <div className="input-wrapper">
                <div className="input-container">
                  <label htmlFor="">
                    Remark. <span>*</span>
                  </label>
                  <textarea
                    type="text"
                    rows={4}
                    {...register("remark", {
                      required: {
                        value: status === "FAILED",
                        message: "Remark is required.",
                      },
                    })}
                    placeholder="Enter Remark"
                  />
                  {errors.remark && (
                    <p className="errorMsg">{errors.remark.message}</p>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="btn-wrapper">
            <button className="green-btn submit-btn">Submit Request</button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default WithdrawRequestConformationModal;
